import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect, useDispatch } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { globalAction } from '../../_actions'
import EventoHeader from './eventoHeader'
import {
  CheckInModal,
  CardEventDetails,
  CardTickets,
  CardEventLocation,
  CardEstablishmentDetails
} from './components'

//! Codigo provisório (excluir)
import ReactPixel from 'react-facebook-pixel'
import { getQueryParamValue } from 'utils'
import { useCarrinhoItensEvento, useEvento } from 'hooks-querys'
import {
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn
} from 'utils/cartItemsFilters'
import { history } from '_helpers'

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  descricao: {
    paddingTop: theme.spacing(2)
  },
  iconPagamentos: {
    padding: theme.spacing(0, 2, 2, 2),
    pointerEvents: 'none'
  },
  content: {
    marginBottom: theme.spacing(3)
  },
  textHeader: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold'
  },
  textDetails: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3)
  },
  loadingContainer: {
    flexGrow: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4, 0, 10, 0) }
  }
})

const Evento = (props) => {
  const dispatch = useDispatch()
  const { classes } = props
  const {
    match: { params }
  } = props
  const { search } = useLocation()
  const couponCode = getQueryParamValue(search, 'couponCode')
  const eventId = params && params.id

  const { data: evento, isLoading: isLoadingEvent } = useEvento(eventId)
  const { data, isLoading: isLoadingCarrinhoItens } = useCarrinhoItensEvento(
    Number(evento?.id)
  )

  const cartItemsWithoutCheckIn = filterItemsWithoutCheckIn(data || [])
  const cartItemsWithCheckIn = filterItemsWithCheckIn(data || [])

  useEffect(() => {
    if (couponCode !== null) {
      dispatch(
        globalAction.applyCouponCodeByLink({
          couponCode: couponCode,
          eventId: eventId
        })
      )
    }
  }, [couponCode])

  useEffect(() => {
    if (params.id) {
      window.scrollTo(0, 0)
    }
  }, [params.id])

  useEffect(() => {
    //! Codigo provisório (excluir)
    //Nyx
    if (evento?.id == 1200 || evento?.id == 1162) {
      const options = {
        autoConfig: true,
        debug: false
      }
      ReactPixel.init('1743951059344817', null, options)
      ReactPixel.track('InitiateCheckout')
      ReactPixel.pageView()
    }
    //EPHEMERAL
    if (evento?.id == 1131) {
      const options = {
        autoConfig: true,
        debug: false
      }
      ReactPixel.init('649866117362455', null, options)
      ReactPixel.track('InitiateCheckout')
      ReactPixel.pageView()
    }
  }, [evento?.id])

  if (isLoadingEvent) {
    return (
      <Container maxWidth="lg" className={classes.loadingContainer}>
        <Box marginY={4}>
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <div className={classes.root}>
      {evento?.id > 0 && (
        <>
          <CheckInModal event={evento} />
          <EventoHeader evento={evento}></EventoHeader>
          <div className={classes.appFrame}>
            <main className={classes.content}>
              <Container>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={7}>
                    <CardEventDetails eventDetails={evento} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Grid container spacing={1}>
                      {cartItemsWithCheckIn?.length > 0 ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <CardTickets
                            eventDetails={evento}
                            carrinhoItens={cartItemsWithCheckIn}
                            isLoading={isLoadingCarrinhoItens}
                            title="Check-in disponível"
                            submitButtonTitle="REALIZAR CHECK-IN"
                            onSubmit={() =>
                              history.push(`/check-in-details/${evento?.id}`)
                            }
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12}>
                        <CardTickets
                          eventDetails={evento}
                          carrinhoItens={cartItemsWithoutCheckIn}
                          isLoading={isLoadingCarrinhoItens}
                          title="Ingressos"
                          submitButtonTitle="COMPRAR INGRESSOS"
                          onSubmit={() =>
                            history.push(`/ingressos/checkout/${evento?.id}`)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <CardEventLocation eventDetails={evento} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <CardEstablishmentDetails
                          establishmentDetails={evento?.estabelecimento}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </main>
          </div>
        </>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    usuario: state.authentication.user
  }
}
const connectedEventoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Evento))
)
export { connectedEventoPage as Evento }
