import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  osName,
  osVersion,
  browserName,
  browserVersion
} from 'react-device-detect'

//! Codigo provisório (excluir)
import ReactPixel from 'react-facebook-pixel'

//? Own imports
import { CarrinhoItem, Pedido, RespostaCampoInscricao } from 'types/api'
import { crudService } from '_services/'
import { history } from '_helpers'

type Payload = {
  textoCupom: string | null
  formaPagamentoId: 0 | 1 | 2 | 3
  carrinhoItens: CarrinhoItem[] | undefined
  parcelas?: number
  respostasCamposInscricao: RespostaCampoInscricao[] | null
}
type Return = {
  pedido: Pedido
}

const postCreateOrder = async (payload: Payload) => {
  const payloadWithPlataforma = {
    ...payload,
    plataforma:
      `web_${osName}-${osVersion}_${browserName}-${browserVersion}`.toLowerCase()
  }
  const { data } = await crudService.post(
    'PagarMeCheckout',
    payloadWithPlataforma
  )
  return data
}

export default function useMutationCreateOrder(payload: Payload) {
  const queryClient = useQueryClient()
  const eventoId =
    (payload &&
      payload.carrinhoItens &&
      payload.carrinhoItens[0] &&
      payload.carrinhoItens[0].lote &&
      payload.carrinhoItens[0].lote.eventoId) ||
    0

  return useMutation<Return>(
    'mutationCreateOrder',
    () => postCreateOrder(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.data || 'Não foi possível realizar o pagamento')
      },
      onSuccess: (data) => {
        //! Codigo provisório (excluir)
        if (eventoId == 1200 || eventoId == 1162 || eventoId == 1131) {
          ReactPixel.trackCustom('Pedido gerado', null)
        }
        queryClient.removeQueries(['carrinhoItens', [eventoId]])
        history.push(`/pedido/${data.pedido.id}`)
      }
    }
  )
}
